import { MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import ControlBar from "../../../components/controlBar/ControlBar";
import DropDown from "../../../components/dropDown/DropDown";
import InputField from "../../../components/InputField/InputField";
import { BannerValue } from "../../../components/statusBanner/models/BannerValue";
import StatusBanner from "../../../components/statusBanner/StatusBanner";
import { _logPage } from "../../../configs/LogConfig";
import { TemplateCategories } from "../../../constants/TemplateCategories";
import { InputValidation } from "../../../components/InputField/models/InputValidation";
import { InputValue } from "../../../components/InputField/models/InputValue";
import { MessageTemplateService as _messageTemplateService } from "../../../services/messageTemplateService/MessageTemplateService";
import "./CreateTemplate.scss";
import {
  TemplateComponent,
  TemplateButtonComponent,
} from "../../../services/messageTemplateService/models/createTemplate/CreateTemplateRequest";
import { UserContext } from "../../../contexts/UserContext";

const _logger = _logPage.getChildCategory("CreateTemplate");

function MapTemplateCategoriesComponent(): JSX.Element[] {
  var categories = Object.entries(TemplateCategories).map((element) => {
    return (
      <MenuItem key={element[0]} value={element[0]}>
        {element[1]}
      </MenuItem>
    );
  });

  return categories!;
}

function CreateTemplate(): JSX.Element {
  const userContext = useContext(UserContext);
  const [loader, setLoader] = useState<boolean>();
  const [quickReplyButtons, setQuickReplyButtons] = useState<[number, string][]>([]);
  const [previewText, setPreviewText] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  const [templateName, setTemplateName] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  const [selectedCategory, setSelectedCategory] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  const [statusBanner, setStatusBanner] = useState<BannerValue>({
    isVisible: false,
    message: "",
    type: "error",
  });

  function addCaracter(caracter: string) {
    setPreviewText({ ...previewText, value: previewText.value + caracter });
  }

  function addVariable(): void {
    var text = previewText.value;
    let regexPattern = /{{2}(\d)}{2}/g;
    let match = text.match(regexPattern);

    let variableCounter = 0;
    if (match != null) {
      variableCounter = match!.length;
    }
    variableCounter += 1;

    addCaracter(`{{${variableCounter}}}`);
  }

  function addQuickReplyButton(): void {
    if (quickReplyButtons.length < 3)
      setQuickReplyButtons([...quickReplyButtons, [quickReplyButtons.length, ""]]);
  }

  function removeQuickReplyButton(id: number): void {
    var clone = [...quickReplyButtons];
    var item = clone.filter((x) => x[0] === id)[0];
    var index = clone.indexOf(item);
    clone.splice(index, 1);
    setQuickReplyButtons(clone);
  }

  function updateQuickReplyButton(id: number, text: string): void {
    var clone = [...quickReplyButtons];
    var item = clone.filter((x) => x[0] === id)[0];
    item[1] = text;
    setQuickReplyButtons(clone);
  }

  function cleanFields() {
    setTemplateName({
      ...templateName,
      value: "",
      errorMessage: "",
      hasError: false,
    });

    setQuickReplyButtons([]);

    setPreviewText({
      ...previewText,
      value: "",
      errorMessage: "",
      hasError: false,
    });
  }

  function isFormValid(): boolean {
    let isValid: boolean = true;
    let message: string = "Existem erros pendentes: Alguns campos precisam de atenção";

    const templateNameRegexRule = /^[a-z]+[\d|\_|a-z]*$/g;
    if (
      !InputValidation.isValid(templateName, true) ||
      !InputValidation.checkByRegex(templateName, templateNameRegexRule)
    ) {
      isValid = false;
      InputValidation.setError(
        templateName,
        "Use letras minúsculas, números ou underlines, começando com uma letra"
      );
      _logger.debug("Template name is not valid!", templateName.value);
      setTemplateName(templateName);
    }

    if (!InputValidation.isValid(selectedCategory)) {
      isValid = false;
      _logger.debug("Category is not valid!", selectedCategory.value);
      setSelectedCategory(selectedCategory);
    }

    if (!InputValidation.isValid(previewText)) {
      isValid = false;
      _logger.debug("Preview text is not valid!", previewText.value);
      setPreviewText(previewText);
    }

    if (!isValid) {
      setStatusBanner({
        ...statusBanner,
        message: message,
        isVisible: true,
      });
    }

    return isValid;
  }

  async function submitAsync() {
    if (!isFormValid()) return;

    setLoader(true);

    var components = [
      new TemplateComponent("Body", previewText.value),
      new TemplateButtonComponent(
        quickReplyButtons.map((button) => new TemplateComponent("QuickReply", button[1], "object:888"))
      ),
    ];

    var response = await _messageTemplateService.CreateTemplateAsync(
      userContext!.botKey,
      userContext!.email,
      templateName.value,
      selectedCategory.value,
      components
    );

    if (response.status === "success")
      setStatusBanner({
        ...statusBanner,
        message: "Modelo de mensagem cadastrado com sucesso! Aguarde a aprovação.",
        type: "info",
        isVisible: true,
      });
    else
      setStatusBanner({
        ...statusBanner,
        message: "Erro ao cadastrar modelo de mensagem, tente novamente!",
        type: "error",
        isVisible: true,
      });

    cleanFields();

    setLoader(false);
  }

  return (
    <>
      {loader ? <bds-loading-page /> : null}
      {statusBanner.isVisible ? (
        <StatusBanner
          message={statusBanner.message}
          type={statusBanner.type}
          onCloseClick={() => setStatusBanner({ ...statusBanner, isVisible: false })}
        />
      ) : null}
      <ControlBar path="/"></ControlBar>
      <bds-grid gap="none" flex-wrap="wrap">
        {/* Left column */}
        <bds-grid sm="12" md="6">
          <div className="container-page">
            <bds-paper class="create-template">
              <h2>Dados</h2>
              <div className="template-form">
                <bds-typo>
                  Preencha os campos abaixo para fazer a submissão de um modelo de mensagem. Lembre-se de
                  seguir as{" "}
                  <a href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines">
                    regras e boas práticas
                  </a>{" "}
                  propostas pelo Facebook.
                </bds-typo>
                <InputField
                  inputValue={templateName}
                  className="select-half"
                  label="Nome do modelo"
                  onChange={(e) => setTemplateName({ ...templateName, value: e.target.value })}
                />
                <DropDown
                  id="message-category-dropdown"
                  className="select-half"
                  hasError={selectedCategory.hasError}
                  errorMessage={selectedCategory.errorMessage}
                  label="Modelo de mensagem"
                  value={selectedCategory.value}
                  onChange={(e: { target: { value: string } }) => {
                    setSelectedCategory({
                      ...selectedCategory,
                      value: e.target.value,
                    });
                  }}
                >
                  {MapTemplateCategoriesComponent()}
                </DropDown>
                <h2>Tipo de mensagem</h2>
                <bds-radio-group>
                  {/* <bds-typo bold="bold">Tipo de mensagem</bds-typo> */}
                  <bds-radio value="text" checked={true} class="radio-button">
                    <bds-icon class="radio-button-icon" name="notes" />
                    Texto
                  </bds-radio>
                  <bds-radio value="image" class="radio-button" disabled>
                    <bds-icon class="radio-button-icon" name="file-image" />
                    Imagem
                  </bds-radio>
                  <bds-radio value="document" class="radio-button" disabled>
                    <bds-icon class="radio-button-icon" name="file-name-pdf" />
                    Documento
                  </bds-radio>
                  <bds-radio value="video" class="radio-button" disabled>
                    <bds-icon class="radio-button-icon" name="video" />
                    Video
                  </bds-radio>
                </bds-radio-group>
              </div>
            </bds-paper>
          </div>
        </bds-grid>
        {/* Right column */}
        <bds-grid sm="12" md="6">
          <div className="container-page">
            <bds-paper class="create-template">
              <h2>Mensagem</h2>
              <InputField
                inputValue={previewText}
                rows={4}
                className="preview-text-area"
                placeholder="Digite aqui a mensagem a ser enviada..."
                onChange={(e) => {
                  setPreviewText({ ...previewText, value: e.target.value });
                }}
              />
              <div className="templates-edition-button-bar">
                <bds-button-icon
                  size="short"
                  variant="secondary"
                  icon="text-style-bold"
                  onClick={() => addCaracter("**")}
                />
                <bds-button-icon
                  size="short"
                  variant="secondary"
                  icon="text-style-italic"
                  onClick={() => addCaracter("__")}
                />
                <bds-button-icon
                  size="short"
                  variant="secondary"
                  icon="text-style-strikethrough"
                  onClick={() => addCaracter("~~")}
                />
                <bds-button size="short" variant="secondary" icon="plus" onClick={() => addVariable()}>
                  variável
                </bds-button>
              </div>
              <div className="quick-reply-buttons-bar">
                {quickReplyButtons.map((button) => QuickReplyButton(button))}
              </div>
              <hr></hr>
              <div className="templates-button-bar">
                <bds-button
                  variant="ghost"
                  icon="button"
                  disabled={quickReplyButtons.length >= 3}
                  onClick={() => addQuickReplyButton()}
                >
                  Adicionar <i>quick reply</i>
                </bds-button>
              </div>
              <bds-button
                id="teste"
                class="template-form-submit"
                variant="primary"
                onClick={async () => submitAsync()}
              >
                Enviar para avaliação
              </bds-button>
            </bds-paper>
          </div>
        </bds-grid>
      </bds-grid>
    </>
  );

  function QuickReplyButton(button: [number, string]): JSX.Element {
    return (
      <bds-chip-clickable class="quick-reply-chip" key={button[0]}>
        <input
          className="quick-reply-button"
          type="text"
          placeholder="Texto do botão"
          maxLength={20}
          size={button[1].length}
          onChange={(event) => updateQuickReplyButton(button[0], event.target.value)}
        ></input>
        <span className="quick-reply-close-button" onClick={() => removeQuickReplyButton(button[0])}>
          x
        </span>
      </bds-chip-clickable>
    );
  }
}

export default CreateTemplate;
