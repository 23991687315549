import React, { useContext, useEffect, useState } from "react";
import "./ListTemplate.scss";
import { MessageTemplateService as _messageTemplateService } from "../../../services/messageTemplateService/MessageTemplateService";
import { AnalyticsService as _analyticsService } from "../../../services/analyticsService/AnalyticsService";
import { QueryStringService as _queryStringService } from "../../../services/queryStringService/QueryStringService";
import { _logPage } from "../../../configs/LogConfig";
import {
  GetTemplatesResourceDataResponse,
  GetTemplatesResponse,
  Template,
} from "../../../services/messageTemplateService/models/getTemplates/GetTemplatesResponse";
import { Text } from "../../../utils/Text";
import { TemplateStatus } from "../../../constants/TemplatesStatus";
import ControlBar from "../../../components/controlBar/ControlBar";
import TextToHtml from "../../../components/textToHtml/TextToHtml";
import { UserContext } from "../../../contexts/UserContext";
import Auth from "../../../components/privateRoute/PrivateRoute";

const _logger = _logPage.getChildCategory("ListTemplate");

function ListTemplate(): JSX.Element {
  const userContext = useContext(UserContext);
  const [messagesTemplates, setMessagesTemplates] = useState<GetTemplatesResponse>();
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    _messageTemplateService
      .GetTemplatesAsync(userContext!.botKey, userContext!.email) //
      .then(async (templates) => {
        setMessagesTemplates(templates);
        setLoader(false);
      });
  }, []);

  return (
    <>
      {loader ? <bds-loading-page /> : null}

      <ControlBar path="/"></ControlBar>
      <bds-paper class="list-template">
        <h1>Modelos de mensagens</h1>
        <hr></hr>
        <bds-accordion-group collapse="multiple">
          {messagesTemplates?.resource.data.map((res) => MessageAccordion(res))}
        </bds-accordion-group>
      </bds-paper>
    </>
  );
}

function MessageAccordion(messageTemplate: GetTemplatesResourceDataResponse): JSX.Element {
  return (
    <bds-accordion key={messageTemplate.id}>
      <bds-accordion-header>
        <bds-chip-tag color={messageTemplate.status === TemplateStatus.Approved ? "success" : "danger"}>
          {messageTemplate.status}
        </bds-chip-tag>
        <b style={{ overflowWrap: "anywhere" }}>{messageTemplate.name}</b>
      </bds-accordion-header>
      <bds-accordion-body>
        {messageTemplate.status !== TemplateStatus.Approved ? StatusBanner(messageTemplate) : <></>}
        <div className="preview-message-container">
          <div style={{ width: "300px" }}>
            <TextToHtml text={Template.getTextComponent(messageTemplate)} />
            {Template.componentsHasElements(messageTemplate, 1) ? (
              TemplateButtons(messageTemplate)
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </bds-accordion-body>
    </bds-accordion>
  );
}

function TemplateButtons(messageTemplate: GetTemplatesResourceDataResponse) {
  return (
    <>
      <hr></hr>
      <div className="buttons-bar-quick-reply">
        {Template.getButtonsComponent(messageTemplate).map((button: { text: any }) => (
          <bds-chip-tag class="buttons-quick-reply" color="outline">
            {button.text}
          </bds-chip-tag>
        ))}
      </div>
    </>
  );
}

function StatusBanner(messageTemplate: GetTemplatesResourceDataResponse) {
  return (
    <bds-banner class="rejected-status-banner" variant="error">
      Rejeitado devido: {messageTemplate.rejected_reason}
    </bds-banner>
  );
}

export default ListTemplate;
