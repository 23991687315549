export class AppData {
  accountToken?: string;
  profile?: {
    name?: string;
    website?: string;
    email?: string;
    phone_number?: string;
  };

  constructor(accountToken?: string, name?: string, website?: string, email?: string, phoneNumber?: string) {
    this.accountToken = accountToken;
    this.profile = {
      name: name,
      website: website,
      email: email,
      phone_number: phoneNumber,
    };
  }
}
