import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import "react-tooltip/dist/react-tooltip.css";
import { useContext, useEffect, useRef, useState } from "react";
import ControlBar from "../../../components/controlBar/ControlBar";
import { _logPage } from "../../../configs/LogConfig";
import { TemplateStatus } from "../../../constants/TemplatesStatus";
import { MessageTemplateService as _messageTemplateService } from "../../../services/messageTemplateService/MessageTemplateService";
import { ConfigurationService as _configurationService } from "../../../services/configurationService/ConfigurationService";
import { CampaignService as _campaignService } from "../../../services/campaignService/CampaignService";
import { FileService as _fileService } from "../../../services/fileService/FileService";
import {
  GetTemplatesResourceDataResponse,
  Template,
} from "../../../services/messageTemplateService/models/getTemplates/GetTemplatesResponse";
import "./CreateCampaign.scss";
import { Text } from "../../../utils/Text";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CSV } from "../../../utils/CSV";
import DropDown from "../../../components/dropDown/DropDown";
import StatusBanner from "../../../components/statusBanner/StatusBanner";
import { BannerValue } from "../../../components/statusBanner/models/BannerValue";
import { TargetAudience } from "../../../models/TargetAudience";
import { VariableValidation } from "../../../models/VariableValidation";
import { Json } from "../../../utils/Json";
import InputField from "../../../components/InputField/InputField";
import { InputValue } from "../../../components/InputField/models/InputValue";
import { InputValidation } from "../../../components/InputField/models/InputValidation";
import { PhoneNumber } from "../../../models/PhoneNumber";
import TextToHtml from "../../../components/textToHtml/TextToHtml";
import { ResourceService } from "../../../services/resourcesService/resourceService";
import { UserContext } from "../../../contexts/UserContext";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import QuestionMark from "@mui/icons-material/QuestionMark";
import { Tooltip } from "react-tooltip";
import TitleHelper from "../../../components/titleHelper/TitleHelper";
import ModeloArquivo from "../createCampaign/modelo-arquivo.png";

const _logger = _logPage.getChildCategory("CreateCampaign");

enum ScheduleMethod {
  NOW = "now",
  SCHEDULE = "schedule",
}

class BotSkill {
  identity: string;
  shortName: string;
  longName: string;

  constructor(identity: string, shortName: string, longName: string) {
    this.identity = identity;
    this.shortName = shortName;
    this.longName = longName;
  }
}

async function getSkills(botKey: string, email: string): Promise<BotSkill[]> {
  var application = await _configurationService.GetApplicationConfiguration(botKey, email);

  var skillNames: BotSkill[] = application.settings.children.map(
    (child: { identity: string; shortName: string; longName: string }) =>
      new BotSkill(child.identity, child.shortName, child.longName)
  );

  return skillNames;
}

async function getStates(
  botKey: string,
  email: string,
  skillName: string
): Promise<[id: string, name: string][]> {
  var response = await _configurationService.GetStatesAsync(botKey, email, skillName);

  if (response === undefined || response.resource === undefined) return [];

  var items: [id: string, name: string][] = response.resource.items.map((state) => [state.id, state.name]);

  return items;
}

async function getFlow(botKey: string, email: string, skillIdentifier: string): Promise<string> {
  var response = await _configurationService.GetFlowIdentifier(botKey, email, skillIdentifier);

  return response;
}

async function getBotDefaultSettings(botKey: string): Promise<{ bot: any; flowState: any } | null> {
  const moduleSettings = "activeCampaignModuleSettings";
  var response = await ResourceService.GetResource(botKey, moduleSettings);
  if (response.status === "success")
    return {
      bot: response.resource.bot,
      flowState: response.resource.flowState,
    };
  else return null;
}

function CreateCampaign(): JSX.Element {
  const userContext = useContext(UserContext);
  const [loader, setLoader] = useState<boolean>(true);
  const [messagesTemplates, setMessagesTemplates] = useState<GetTemplatesResourceDataResponse[]>([]);
  const [buttons, setButtons] = useState<any>([]);
  const [botSkills, setBotSkills] = useState<BotSkill[]>([]);
  const [flowStates, setFlowStates] = useState<[string, string][]>([]);
  const [selectedScheduleMethod, setSelectedScheduleMethod] = useState<ScheduleMethod>(ScheduleMethod.NOW);
  const [selectedScheduleTime, setSelectedScheduleTime] = useState<any>();
  const [targetAudience, setTargetAudience] = useState<TargetAudience[]>([]);
  const [variablesLabels, setVariables] = useState<string[]>([]);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });
  const [selectedBotSkill, setSelectedBotSkill] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });
  const [selectedState, setSelectedState] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  const [campaignName, setCampaignName] = useState<InputValue>({
    value: "",
    errorMessage: "",
    hasError: false,
  });

  const [statusBanner, setStatusBanner] = useState<BannerValue>({
    isVisible: false,
    message: "",
    type: "error",
  });

  const [defaultSettings, setDefaultSettings] = useState<boolean>(false);

  useEffect(() => {
    getBotDefaultSettings(userContext!.botKey) //
      .then(async (response) => {
        if (response !== null) {
          setDefaultSettings(true);
          setSelectedBotSkill({ ...selectedBotSkill, value: response.bot });
          setSelectedState({ ...selectedState, value: response.flowState });
        }
      });

    _messageTemplateService
      .GetTemplatesAsync(userContext!.botKey, userContext!.email, true) //
      .then(async (templates) => {
        setMessagesTemplates(templates.resource.data);

        if (!defaultSettings)
          getSkills(userContext!.botKey, userContext!.email) //
            .then(async (skills) => {
              setBotSkills(skills);
              setLoader(false);
            });
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedScheduleMethod === ScheduleMethod.NOW)
        //
        setSelectedScheduleTime(Date.now);
    }, 1 * 1000); // every 1 seconds

    return () => clearInterval(interval);
  }, [selectedScheduleMethod]);

  useEffect(() => {
    setVariables(getVariablesInSelectedTemplate());
    setButtons(getVariablesSelectedTemplate());
  }, [selectedMessageTemplate.value]);

  useEffect(() => {
    cleanPhoneNumberAndVariables();
  }, [variablesLabels]);

  useEffect(() => {
    setLoader(true);
    getStates(
      userContext!.botKey, //
      userContext!.email, //
      selectedBotSkill.value
    ) //
      .then(async (states) => {
        setFlowStates(states);
        setLoader(false);
      });
  }, [selectedBotSkill.value]);

  function GetMessageTemplate(): GetTemplatesResourceDataResponse | null {
    if (!InputValidation.isValid(selectedMessageTemplate, false)) return null;

    let template = messagesTemplates.find((template) => template.id === selectedMessageTemplate.value);

    return template!;
  }

  function getTextSelectedTemplate(): string {
    return Template.getTextComponent(GetMessageTemplate());
  }

  function getVariablesSelectedTemplate(): any {
    return Template.getButtonsComponent(GetMessageTemplate());
  }

  function getVariablesInSelectedTemplate(): string[] {
    var text = getTextSelectedTemplate();

    let regexPattern = /{{2}(\d)}{2}/g;
    let match = text.match(regexPattern);

    var size = match !== null ? match!.length : 0;
    var result: string[] = [];

    for (let index = 1; index <= size; index++) {
      result.push(`{{${index}}}`);
    }

    return result;
  }

  function addTargetAudience(phoneNumber: string = "", variableValues: string[] = []) {
    var clone = targetAudience;
    clone.push(newTargetAudience(phoneNumber, variableValues));

    setTargetAudience(clone);
  }

  function cleanPhoneNumberAndVariables(initialize: boolean = true) {
    var clone: TargetAudience[] = [];

    setTargetAudience(clone);
  }

  function newTargetAudience(phoneNumber: string, variableValues: string[]): TargetAudience {
    var variables: [string, string][] = [];
    for (let index = 0; index < variablesLabels.length; index++) {
      var label = variablesLabels[index];
      var value = variableValues[index];

      variables.push([label, value]);
    }

    return new TargetAudience(phoneNumber, variables);
  }

  function removePhoneNumberAndVariables(position: number) {
    var clone = [...targetAudience];
    var item = clone[position];
    var index = clone.indexOf(item);
    clone.splice(index, 1);

    setTargetAudience(clone);
  }

  async function uploadFile(files: FileList) {
    var parsedFile = await CSV.ParseFile(files[0]);
    var data = parsedFile.data;

    for (let index = 0; index < data.length; index++) {
      let variableValues: string[] = [];
      let dataLine = data[index];
      let keys = Object.keys(dataLine);

      for (let i = 1; i < keys.length; i++) {
        variableValues.push(dataLine[keys[i]].trim());
      }

      let phoneNumber = new PhoneNumber(dataLine.telefone);

      addTargetAudience(phoneNumber.value, variableValues);
    }
  }

  function updatePhoneNumber(position: number, value: string, format: boolean = false) {
    var clone = [...targetAudience];
    var item = clone[position];
    item.phoneNumber = new PhoneNumber(value);

    if (format) item.phoneNumber.formatNumber();

    setTargetAudience(clone);
  }

  function updateVariable(position: number, variable: number, value: string) {
    var clone = [...targetAudience];
    var item = clone[position];
    item.variables[variable].setValue(value);

    setTargetAudience(clone);
  }

  function isFormValid(): boolean {
    let isValid: boolean = true;
    let message: string = "Existem erros pendentes: Alguns campos precisam de atenção";
    console.log(campaignName.value);
    if (!InputValidation.isValid(campaignName)) {
      isValid = false;
      _logger.debug("Campaign name is not valid!", campaignName.value);
      setCampaignName(campaignName);
    }

    if (!InputValidation.isValid(selectedMessageTemplate)) {
      isValid = false;
      _logger.debug("Message templete selected is not valid!", selectedMessageTemplate.value);
      setSelectedMessageTemplate(selectedMessageTemplate);
    }

    if (!InputValidation.isValid(selectedBotSkill)) {
      isValid = false;
      _logger.debug("Bot skill selected is not valid!", selectedBotSkill.value);
      setSelectedBotSkill(selectedBotSkill);
    }

    if (!InputValidation.isValid(selectedState)) {
      isValid = false;
      _logger.debug("Bot point selected is not valid!", selectedState.value);
      setSelectedState(selectedBotSkill);
    }

    if (targetAudience.length == 0) {
      isValid = false;
      message += "\nÉ necessário pelo menos um contato para audiência!";
      _logger.debug("Target audience is empty!");
    } else {
      for (let index = 0; index < targetAudience.length; index++) {
        const element = targetAudience[index];
        element.Validate();
        isValid = false;
      }
      _logger.debug("Target audience contains invalid itens!", Json.StringifyFormat(targetAudience));
      setTargetAudience(targetAudience);
    }

    if (selectedScheduleMethod === ScheduleMethod.SCHEDULE && selectedScheduleTime < new Date()) {
      isValid = false;
      message += "\nA data e hora informada não são válidas para agendamento!";
      _logger.debug("The selected time is not valid!", selectedScheduleTime);
    }

    if (!isValid) {
      setStatusBanner({
        ...statusBanner,
        message: message,
        isVisible: true,
      });
    }

    return isValid;
  }

  async function submit() {
    if (!isFormValid()) return;

    var file = CSV.CreateFile(targetAudience);
    let url = await _fileService.UploadAsync(userContext!.botKey, file);

    let skillIdentity = botSkills.find((skill) => skill.shortName == selectedBotSkill.value)?.identity;

    let flowId = await getFlow(userContext!.botKey, userContext!.email, skillIdentity!);

    await _campaignService.CreateBatchCampaignAsync(
      userContext!.botKey,
      userContext!.email,
      campaignName.value,
      skillIdentity!,
      flowId,
      selectedState.value,
      url,
      selectedScheduleTime
    );
  }

  return (
    <>
      {loader ? <bds-loading-page /> : null}
      {statusBanner.isVisible ? (
        <StatusBanner
          message={statusBanner.message}
          type={statusBanner.type}
          onCloseClick={() => setStatusBanner({ ...statusBanner, isVisible: false })}
        />
      ) : null}
      <ControlBar path="/" />
      <bds-paper class="create-campaign">
        <div>
          <h3>Conteúdo</h3>
          <InputField
            inputValue={campaignName}
            className="select-half"
            label="Nome da campanha"
            style={{ marginBottom: "25px" }}
            onChange={(e) =>
              setCampaignName({
                ...campaignName,
                value: e.target.value,
              })
            }
          />
          <bds-typo style={{ marginTop: "20px" }}>
            Escolha o modelo de mensagem que deseja enviar para sua audiência. Apenas modelos aprovados pelo
            Facebook estarão disponíveis.
          </bds-typo>
          <DropDown
            id="message-template-dropdown"
            className="select-half"
            hasError={selectedMessageTemplate.hasError}
            errorMessage={selectedMessageTemplate.errorMessage}
            label="Modelo de mensagem"
            value={selectedMessageTemplate.value}
            onChange={(e: { target: { value: string } }) => {
              setSelectedMessageTemplate({
                ...selectedMessageTemplate,
                value: e.target.value,
              });
            }}
          >
            {messagesTemplates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </DropDown>
        </div>
        <div>
          <h3 className="section-title">Pre-visualização da mensagem</h3>
          <div className="preview-message-container">
            <div style={{ width: "300px" }}>
              {InputValidation.isValid(selectedMessageTemplate, false) ? (
                <bds-paper class="preview-message">
                  <TextToHtml text={getTextSelectedTemplate()} />
                </bds-paper>
              ) : (
                <div>
                  Escolha um <i>template</i> para ver a mensagem aqui!
                </div>
              )}
              {buttons !== undefined && buttons.length > 1 ? TemplateButtons() : null}
            </div>
          </div>
        </div>
        <div>
          <div className="public-variables-title">
            <TitleHelper title="Público alvo e variáveis" place="bottom">
              <div style={{ display: "table-caption" }}>
                <p>
                  O arquivo para upload deve estar em formato <i>.CSV</i> e deve seguir o seguinte formato:
                </p>
                <img src={ModeloArquivo} />
              </div>
            </TitleHelper>
            <div style={{ display: "flex" }}>
              <input
                id="upload-file"
                onChange={async (event) => uploadFile(event.target.files!)}
                type="file"
                disabled={selectedMessageTemplate.value == ""}
                hidden
              />
              <bds-button-icon
                variant="tertiary"
                icon="upload"
                size="short"
                disabled={selectedMessageTemplate.value == ""}
                onClick={() => document.getElementById("upload-file")?.click()}
              >
                {" "}
              </bds-button-icon>
              <bds-button-icon
                variant="tertiary"
                icon="plus"
                size="short"
                disabled={selectedMessageTemplate.value == ""}
                style={{ marginLeft: "5px" }}
                onClick={() => addTargetAudience()}
              />
            </div>
          </div>
          <div className="phonenumber-variables">
            {targetAudience.map((target) =>
              NumberAndVariable(targetAudience.indexOf(target), target.phoneNumber, target.variables)
            )}
          </div>
          <hr />
          <div style={{ float: "right" }}>
            <span>{targetAudience.length} contatos de audiência</span>
            {targetAudience.filter((x) => !x.Validate()).length > 0 ? (
              <span style={{ color: "#d32f2f" }}>
                {" "}
                | {targetAudience.filter((x) => !x.Validate()).length} com erro
              </span>
            ) : null}
          </div>
        </div>
        {!defaultSettings ? (
          <div>
            <h3 className="section-title">Direcionamento de resposta</h3>
            <bds-typo>
              Os contatos que respondem suas mensagens ativas podem ser direcionados a um fluxo específico do
              chatbot, ou a um atendente específico. Neste último caso, lembre-se que o atendente deve
              pertencer a este chatbot.
            </bds-typo>
            <bds-grid direction="row" xxs="12" gap="none">
              <bds-grid xxs="6">
                <DropDown
                  id="bot-dropdown"
                  className="select-full"
                  hasError={selectedBotSkill.hasError}
                  errorMessage={selectedBotSkill.errorMessage}
                  label="Bot"
                  value={selectedBotSkill.value}
                  onChange={(e: { target: { value: string } }) => {
                    setSelectedBotSkill({
                      ...selectedBotSkill,
                      value: e.target.value,
                    });
                  }}
                >
                  {botSkills.map((skill) => (
                    <MenuItem key={skill.shortName} value={skill.shortName}>
                      {skill.longName}
                    </MenuItem>
                  ))}
                </DropDown>
              </bds-grid>
              <bds-grid xxs="6">
                <DropDown
                  id="bot-point-dropdown"
                  className="select-full"
                  hasError={selectedState.hasError}
                  errorMessage={selectedState.errorMessage}
                  label="Ponto do bot"
                  disabled={selectedBotSkill.value == ""}
                  value={selectedState.value}
                  onChange={(e: { target: { value: string } }) => {
                    setSelectedState({
                      ...selectedState,
                      value: e.target.value,
                    });
                  }}
                >
                  {flowStates.map((flowState) => (
                    <MenuItem key={flowState[0]} value={flowState[0]}>
                      {flowState[1]}
                    </MenuItem>
                  ))}
                </DropDown>
              </bds-grid>
            </bds-grid>
          </div>
        ) : null}
        <h3 className="section-title">Quando enviar?</h3>
        <bds-grid direction="row" xxs="12">
          <bds-grid xxs="6">
            <div>
              <RadioGroup
                name="when-send"
                value={selectedScheduleMethod.valueOf()}
                onChange={(event: { target: { value: string } }) => {
                  let selected: ScheduleMethod = event.target.value as ScheduleMethod;
                  setSelectedScheduleMethod(selected);
                }}
              >
                <FormControlLabel value="now" control={<Radio />} label="Agora" />
                <FormControlLabel value="schedule" control={<Radio />} label="Agendar" />
              </RadioGroup>
            </div>
          </bds-grid>
          <bds-grid xxs="6">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Date e hora"
                disabled={selectedScheduleMethod === ScheduleMethod.NOW}
                value={selectedScheduleTime}
                disablePast
                className="select-full"
                onChange={(event) => setSelectedScheduleTime(event)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </bds-grid>
        </bds-grid>
        <bds-button
          id="submit-button"
          class="template-form-submit"
          variant="primary"
          onClick={async () => submit()}
        >
          {selectedScheduleMethod === ScheduleMethod.NOW ? "Enviar agora" : "Agendar envio"}
        </bds-button>
      </bds-paper>
    </>
  );

  function TemplateButtons() {
    return (
      <>
        <hr></hr>
        <div className="buttons-bar-quick-reply">
          {buttons.map((button: { text: any }) => (
            <bds-chip-tag key={buttons.indexOf(button)} class="buttons-quick-reply" color="outline">
              {button.text}
            </bds-chip-tag>
          ))}
        </div>
      </>
    );
  }

  function NumberAndVariable(position: number, phoneNumber: PhoneNumber, variables: VariableValidation[]) {
    return (
      <div key={"phone" + position} className="number-variable-line">
        <bds-button-icon
          variant="secondary"
          icon="delete"
          size="short"
          onClick={() => removePhoneNumberAndVariables(position)}
        />
        <TextField
          label="Nº de telefone"
          variant="outlined"
          error={!phoneNumber.isValid}
          helperText={!phoneNumber.isValid ? "Não é válido" : ""}
          value={phoneNumber.value}
          onChange={(event: { target: { value: string } }) => updatePhoneNumber(position, event.target.value)}
          onBlur={(event: { target: { value: string } }) =>
            updatePhoneNumber(position, event.target.value, true)
          }
        />
        {variables.map((variable) => (
          <TextField
            key={position.toString() + variable.label}
            id={position.toString()}
            className="variables-line"
            label={"Variável " + variable.label}
            variant="outlined"
            error={variable.hasError}
            helperText={variable.hasError ? variable.errorMessage : ""}
            value={variable.value}
            onChange={(event: { target: { value: string } }) =>
              updateVariable(position, variables.indexOf(variable), event.target.value)
            }
          />
        ))}
      </div>
    );
  }
}

export default CreateCampaign;
